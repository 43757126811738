@mixin serif-type {
  font-family: utopia-std-display, serif;
  font-weight: 400;
}

@mixin sans-type {
  font-family: acumin-pro, sans-serif;
  font-weight: 400;
}

// condensed defaults to the semi-bold weight (600) use 400 as an override as needed
@mixin condensed-type {
  font-family: acumin-pro-condensed, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

// font scale
$text-xl: 41px;
$text-l: 30px;
$text-m: 21px;
$text-s: 18px;
$text-xs: 16px;
$text-button-l: 23px;

// Override bootstrap sizing
small,
.small {
  font-size: $text-s;
}

// typography helpers
@mixin jumbo-headline {
  @include condensed-type;
  color: $slate;
  font-size: $text-xl;
  line-height: 91%;
  letter-spacing: -0.02em;
}

@mixin sans-heading {
  font-weight: 600;
  font-size: $text-m;
}

@mixin list-text {
  @include sans-type;
  font-size: $text-xs;
  line-height: 118%;
  // letter-spacing: .01em;
  font-weight: 500;
}

@mixin input-field {
  padding: 20px 15px;
  text-align: center;
  font-size: $text-m;
}

@mixin fine-print {
  // @include condensed-type();
  font-weight: 400;
  font-size: 0.875rem !important;
  color: $slate;
  letter-spacing: .01em;
  line-height: 118%;
  vertical-align: text-top;
}

@mixin paragraph {
  font-weight: 600;
  line-height: 118%;
}

@mixin pretty-link {
  color: $slate;
  text-decoration: none;
  border-bottom: 1px solid $light-primary;
  transition: border-bottom 0.2s ease-in-out;

  &:hover {
    border-bottom: 1px solid $slate;
  }
}

// Migrated from app.scss -- deal with these later

.text-tiny {
  font-size: 0.7rem;
  color: purple;
}

.text-smaller {
  font-size: $text-xs;
}

// radii
$radius-sm: 3px;
$radius-md: 7px;

//spacing
// bootstrap override to set margins as close to 25px as possible:
.mx-4 {
  margin-left: 1.5625rem !important;
  margin-right: 1.5625rem !important;
}

// heights
$height-l: 50px;

// Color
$theme-colors: (
  "primary": #be9955,
  "danger": #ff4136,
);
.color-primary {
  color: map.get($theme-colors, "primary");
}

$slate: #4a4444;
$slate-faded: rgba(74, 68, 68, 0.1);
$dark-text: #252525;
$dark-text-2: #444444;
$medium-gray: #6c757d;
$medium-gray-2: #4a4a4a;
$light-primary: #be995566;
$gray-background: #f6f8fd;
$page-tone: #fbf9f5;

// effects
@mixin icon-shadow {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

@mixin pretty-focus {
  outline: none;
  outline: 3px solid $light-primary;
  transition: all .1s ease-out;
  transform: scale(1.02);
}

// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 380px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// $font-family-base: "acumin-pro"; don't think we need this?
@import "~bootstrap/scss/bootstrap.scss";
