@use "sass:map";
@import "custom-theme.scss";

// ___________________________________________
// LAYOUT CONTROL
// ___________________________________________

body {
  @include sans-type;
  background: url(./assets/images/gift-welcome-bg.jpg) no-repeat left top;
  background-size: cover;
  overflow-y: auto;
  color: $slate;
  text-rendering: optimizeLegibility;
  // color: blue;
}

.spacer {
  flex-grow: 1;
  max-height: 15vh;
}

.divider {
  background-color: $slate-faded;
  height: 1px;
  // width: 50%;
}

.spacer-extend {
  max-height: 100vh;
}

.page-container {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #ffffff 5%, #e5e5e5);
  min-height: 100vh;
  padding-top: 0.25rem;

  .step-count {
    color: #adb5bd;
  }

  .step-text {
    @include sans-heading;
    padding-top: 5px;
  }
}

.poster-bg {
  background: url(./assets/images/image81.png) no-repeat left top;
  background-size: cover;
}

.max-w-50 {
  max-width: 50%;
}

// ___________________________________________
// BUTTONS
// ___________________________________________

@mixin cta-btn {
  @include condensed-type;
  font-size: $text-button-l;
  line-height: 1;
  text-transform: capitalize;
  border-radius: $radius-md;
  border-width: 2px;
  border-color: map.get($theme-colors, "primary") !important;
  height: 50px !important;
  color: $page-tone;
  max-width: 8rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  letter-spacing: .01em;
  padding-top: 3px;
}

@mixin action-button {
  text-align: center;
  @include condensed-type;
  font-size: $text-xs;
  line-height: 1;
  background: rgba(74, 68, 68, 0.1);
  border-radius: $radius-md;
  border: none;
  font-weight: 400;
  padding: 5px 9px 6px 9px;
  letter-spacing: 0.02em;
  text-transform: initial;
  text-decoration: none;
  color: $slate;
  height: 30px;
}

@mixin icon-md {
  min-width: 35px;
  min-height: 35px;
}

.cta-button {
  @include cta-btn;
}

.light-cta-button {
  @include cta-btn;
  border-color: map.get($theme-colors, "primary") !important;
  color: map.get($theme-colors, "primary") !important;
  background-color: #fff !important;
  box-shadow: none;
}

.square-button {
  border-radius: $radius-sm !important;
  border-style: dashed !important;
  border-width: 1px;
  min-width: 5rem;
  min-height: 5rem;
  text-align: center !important;
}

.action-btn {
  @include action-button;
}

.close-btn {
  @include icon-md;
}

.btn-primary:disabled {
  color: $page-tone;
  opacity: .4;
}

// ___________________________________________
// FORMS, INPUTS, FIELDS
// ___________________________________________

input:focus,
select:focus {
  outline: none;
  box-shadow: none;
}

.styled-greeting-text {
  color: map.get($theme-colors, "primary");
  @include serif-type;
}

.input {
  @include sans-type;

  option {
    @include sans-type;
  }

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
}

.form-check-input {
  background-color: #fff !important;
  border-color: map.get($theme-colors, "primary") !important;
  border-width: 2px;
}

.form-check-input:checked {
  background-color: map.get($theme-colors, "primary") !important;
}

.form-control {
  min-height: 50px;
  font-size: $text-m;
}

.input-field {
  /* background-color: #e8e2d4 !important; */
  border-color: #e8e2d4 !important;
  border-radius: $radius-sm !important;
  border-style: solid !important;
  border-width: 1px;
  @include sans-type;

  option {
    @include sans-type;
  }
}

.input-field:focus {
  outline: none;
  border: 3px solid map.get($theme-colors, "primary");
  line-height: 39px;
}

.tc-checkbox {
  @include fine-print;
  a { 
    @include pretty-link;
}
}

.email-input:invalid {
  border-color: red !important;
  border-width: 2px !important;
  background-color: #e01d1d1a !important;
  outline: none !important;
}

// ___________________________________________
// MISC
// ___________________________________________

.blur {
  filter: blur(3px);
}

.toast-header {
  background-color: #e8e2d4;
  color: $dark-text;
}

.toast-body {
  background-color: map.get($theme-colors, "primary");
  color: #fff;
}

.page-tone {
  background-color: $page-tone;
}

// ___________________________________________
// ???? deprecated, maybe?
// ___________________________________________

// .text-16 {
//     font-size: 0.75rem;
// }

// .card-toggle {
//   position: absolute;
//   bottom: 2rem;
//   left: 50%;
//   transform: translateX(-50%);
//   background-color: map.get($theme-colors, "primary");
//   border-radius: 25px;
//   padding: 0.4rem 0.55rem;

//   .btn-group {
//     label {
//       border-radius: 2rem !important;
//       padding: 0.25rem 1.25rem !important;
//     }

//     label:first-child {
//       margin-right: 1.5rem;
//     }

//     label:last-child {
//       margin-left: 1.5rem;
//     }
//   }
// }
// ___________________________________________
// Bootstrap modal
// ___________________________________________

.modal-dialog {
  margin: 25px;
}

.modal-dialog-centered {
  min-height: calc(100% - 50px);
  max-height: calc(100% - 50px);
}

.modal-fullscreen {
  max-width: calc(100% - 50px);
}

.modal-header {
  @include sans-heading;
  border-bottom: none;
  font-weight: 600;
  padding-bottom: 15px;
  padding: 12px 15px 0px 15px;
  display: block;
}

.modal-title {
  line-height: 1;
}

.bottom-button {
  // margin-bottom: 25px !important;
  // color: green !important;
}

// ___________________________________________
// SavePage.tsx
// ___________________________________________

.full-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transform: scale(1.1);
  overflow: hidden;
}

// ___________________________________________
// HowItWorks.tsx
// ___________________________________________

.how-it-works-page {
  .img-wrapper {
    display: flex;
    align-items: center;
  }

  img {
    /* height: 156px; */
    width: 200px;
    // margin-left: 50%;
    transform: translateX(35px);
  }

  .sub-title {
    padding-bottom: 15px;
  }

  .cta-button {
    max-width: 100%;
  }
  .modal-content-list {
    display: flex;
    flex-direction: column;
    text-align: left;
    flex-grow: 1;
    margin-bottom: 1rem;
    &__row {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
      padding: 1rem 0.15rem;
      border-bottom: 1px solid $slate-faded;
      &:first-of-type {
        border-top: 1px solid $slate-faded;
      }
      &--img {
        @include icon-shadow;
        width: 44px;
        height: 44px;
        transform: none;
        border-radius: 50%;
      }
      &--label {
        @include list-text;
        padding-right: 15px;
        text-align: left;
      }
    }
  }
}

// ___________________________________________
// WelcomePage.tsx
// ___________________________________________

.welcome {
  background-image: url(./assets/images/welcome-bg.png);
  background-repeat: no-repeat;
  background-size: cover;

  .button-wrapper {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;

    button {
      @include jumbo-headline;
      width: 300px;
      height: 300px;
      background: #ffffff;
      border: 5px solid rgba(0, 0, 0, 0.15);
      border-radius: 200px;
      cursor: pointer;
      padding: 0px 30px;
    }
  }

  // don't think we need these below
  // h1 {
  //     color: #fff !important;
  //     font-weight: bold !important;
  // }

  // h6 {
  //     color: #252525 !important;
  // }
}

.main-title {
  @include condensed-type;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  font-size: 48px;
  line-height: 90%;
  color: #ffffff;
}

// ___________________________________________
// FrontEditPage.tsx
// ___________________________________________

.dark-gray-2 {
  color: $dark-text-2;
}

// ___________________________________________
// SenderFlowPage.tsx
// ___________________________________________

.sender-carousel {
  .carousel-inner {
    overflow: auto !important;
  }
}

// ___________________________________________
// FinalPreviewPage.tsx
// ___________________________________________

.medium-gray-icon {
  color: $medium-gray;
}

.sub-title {
  text-align: center;
  @include sans-heading;
}

.save-success-modal {
  .modal-header {
    @include jumbo-headline;
    font-weight: 600;
  }
}

// ___________________________________________
// Progress header
// ___________________________________________

.animated-step {
  max-width: 100vw;
  max-height: 100vh;
}

.step-1 {
  border-top: 5px solid !important;
  border-image: linear-gradient(to right, map.get($theme-colors, "primary") 25%, $light-primary 25%) 5;
}

.step-2 {
  border-top: 5px solid !important;
  border-image: linear-gradient(to right, map.get($theme-colors, "primary") 50%, $light-primary 50%) 5;
}

.step-3 {
  border-top: 5px solid !important;
  border-image: linear-gradient(to right, map.get($theme-colors, "primary") 75%, $light-primary 75%) 5;
}

.step-4 {
  border-top: 5px solid map.get($theme-colors, "primary") !important;
}

.top-border-chrome {
  border-top: 5px solid map.get($theme-colors, "primary");
}

// ___________________________________________
// Claim gift page
// ___________________________________________

.claim-gift-page {
  h5:first-of-type {
    // flagging this – style doesn't seem right
    @include sans-type;
    color: red;
  }

  .cta-button {
    max-width: none;
  }

  .front-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

// ___________________________________________
// 
// ___________________________________________



// ___________________________________________
// To/from page (aka back label page)
// ___________________________________________

.back-edit-page {
  .label-form {
    h4 {
      @include serif-type;
      font-style: italic;
      font-size: $text-s;
      letter-spacing: .02em;
    }

    .greeting-text {
      min-height: 10rem;
    }

    .input-field {
      @include input-field;
      &:focus {
        @include pretty-focus;
      }
    }
  }
}

// ___________________________________________
// Wine chooser page
// ___________________________________________

.wine-chooser-page {
  // span {
  //   font-weight: 600;
  // }

  .helper-text {
    @include serif-type;
    font-style: italic;
    font-size: 1.9rem;
    line-height: 120%;
    text-align: center;
  }

  .skip-button {
    @include action-button;
    max-width: 3rem;
    align-self: center;
  }

  .light-cta-button, .cta-button {
    max-width: none;
  }
}

// ___________________________________________
// 
// ___________________________________________
.front-edit-page,
.front-preview-page,
.back-edit-page,
.back-preview-page,
.label-confirmation-page,
.final-preview-page,
.save-page,
.wine-chooser-page,
.claim-gift-page {
  .crown {
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 1rem;
    min-height: 5rem;
    background: url(./assets/images/form-bg2.png);
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-filter: drop-shadow(0px -16px 10px rgba(34, 34, 34, 0.1));
    filter: drop-shadow(0px -16px 10px rgba(0, 0, 0, 0.1));
  }

  .label-form {
    height: 100vh;
    background-color: $page-tone;

    .greeting-text {
      @include input-field;
      margin: 25px;
      min-width: calc(100% - 50px);
      line-height: 118%;
      min-height: 60px;
      border-radius: $radius-md !important;
      padding: 30px 15px 10px;

      &:focus {
        @include pretty-focus;
      }
    }

    .front-img {
      position: relative;
      width: 335px;
      height: 340px;
      max-width: 335px;
      max-height: 340px;
      border-radius: $radius-md;
      overflow: hidden;

      &__bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: scale(1.1);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .tappable-area {
        position: relative;
        color: $medium-gray;
        background-color: #ffffff;
        color: $dark-text-2;
        width: 198px;
        height: 198px;
        border-radius: 100px;

        .inner {
          margin-top: 36%;
          position: relative;
          text-align: center;
        }
      }
    }
  }
}

.front-edit-page {
  .label-form {
    .greeting-text {
      width: 90%;
    }

    .greeting-text:focus {
      width: 281px;
    }
  }
}






.response-edit-page {
  background-color: #fff !important;
  min-height: fit-content;

  .response-heading {
@include paragraph;
padding-right: 50px;
  }

  .input-field {
    display: block;
    min-height: 10rem;
    width: 100%;
    margin-left: 8rem !important;
    margin-right: 8rem !important;
    border-radius: $radius-md !important;
    padding: 15px;
    &:focus {
      transition: all .1s ease;
      padding-top: 10px;
    }
  }

  .cta-button {
    width: 100%;
    max-width: 100%;
    padding-top: 0;
  }
}

.response-message-page {
  .response-heading {
    @include paragraph;
    padding-right: 20px;
  }

  a {
    @include pretty-link;
    display: inline-block;
  }

  .save-form-container {

    .send-form {
      .email-input {
        font-size: $text-m;
      }

      label {
        @include fine-print;
        a {
          @include pretty-link;
        }
      }
    }
  }
  .light-cta-button,
  .cta-button {
    font-size: $text-button-l;
  }
}

.wine-selector {
  @include condensed-type;
  font-weight: 600;
  text-transform: uppercase;
  background-color: map.get($theme-colors, "primary");
  border-radius: $radius-md;
  color: #fff;
  padding-top: 6px;
  padding-left: 15px;
  padding-bottom: 0.5rem;
  font-size: $text-button-l;
  letter-spacing: 0.01em;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  width: auto;
  border: none;
  height: $height-l;

  &__toggle {
    border-radius: 0;
    width: 100%;
    color: #fff !important;
  }

  &__menu {
    width: 100%;
    background-color: map.get($theme-colors, "primary");
    color: #fff;
  }
}

.final-preview-page,
.claim-gift-page,
.save-page,
.label-confirmation-page {
  .card-toggle {
    position: relative;
    left: inherit;
    bottom: inherit;
    transform: none;
  }

  .label-form {
    .wine-name {
      font-size: $text-s;
    }

    .divider {
      width: 80%;
    }

    .styled-greeting-text {
      color: #000;
      font-size: 1.9rem;
      line-height: 38px;
    }

    .from-text {
      @include serif-type;
      font-style: italic;
      font-size: $text-s;
    }
  }
}

.label-confirmation-page {
  .front-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.final-preview-page {
  .front-img {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.save-page {
  background-repeat: no-repeat;
  background-size: cover;

  .inner-container {
    z-index: 100;
    background-color: #fff;
    .divider {
      width: 100%;
    }

    .nav-btn-borders {
      width: calc(100% - 40px);
      border-top: 1px solid $slate-faded;
      margin-top: 30px;
      border-bottom: 1px solid $slate-faded;
      padding-bottom: 18px;
    }
    .save-form-container {
      border: none;
      .form-check-label {
        @include fine-print;
        font-weight: unset;;
        padding: 2px 10px 0px 4px;
      }
      .email-input {
        @include input-field;
        text-align: left;
        padding: 6px 15px 10px;
      }
    }

    .privacy-link {
      @include fine-print;
      @include pretty-link;
    }
  }
}

.final-preview-page {
  background-color: #e9ecef !important;

  .top-message {
    span {
      text-align: center;
      font-weight: 600;
      line-height: 118%;
    }
  }

  .action-btn {
    @include action-button;
  }
}

.thanks-flow-modal {
  .modal-header {
    border-bottom: none;
    padding: 1rem 1rem 0;
  }
}

.source-selector-modal {
  width: 90%;
  max-width: none !important;
  margin: 40% auto;
}

.tc-modal {
  width: 90%;
  margin: 20% auto;

  span {
    font-size: 0.7rem;
    line-height: 0.6rem;
    height: 80%;
    overflow: scroll;
  }
}

.save-form-container {
  border: none;

  .email-input {
    @include input-field;
  }

  .privacy-link {
    font-size: 0.8rem;
  }
}

.label-content-wrapper {
  width: 100%;
}

.flip-card {
  min-height: 74.5vh;
  min-width: 100vw;
  perspective: 1000px;
  margin-top: 0;

  .flip-card-inner {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 74.5vh;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
}

@include media-breakpoint-up(sm) {
  .flip-card {
    margin-top: 15%;
  }
}

.flip-card.show-back .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  .label-form {
    min-height: 100vh;
  }
}

.flip-card-back {
  transform: rotateY(180deg);
}
